import classNames from 'classnames';
import React from 'react';

import styles from './empty-page.module.sass';

// Props
type EmptyPageProps = {
  className?: string;
  children: React.ReactNode;
};

const EmptyPage: React.FC<EmptyPageProps> = ({ className, children }) => (
  <div className={classNames(styles.window, className)}>
    <div className={styles.wrapper}>{children}</div>
  </div>
);

export default EmptyPage;
