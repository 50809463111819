import cx from 'classnames';
import React from 'react';
import Select, { Props as SelectProps } from 'react-select';

import s from './select.module.sass';

// @ts-ignore
// Library has some type errors and does not accept JSX as children
interface CustomSelectProps extends SelectProps {
  label: string;
  inputTheme?: string;
  instanceId?: string;
  value?: {
    label: string | number | JSX.Element;
    value: string | number;
  };
  options: {
    label: string | number | JSX.Element;
    value: string | number;
  }[];
}

const customSingleValue = ({ data }: any) => <span title={data.label}>{data.label}</span>;

const CustomOption = ({ innerProps, isDisabled, isSelected, isFocused, data }: any) =>
  !isDisabled ? (
    <div
      {...innerProps}
      title={data.label}
      className={cx(s.option, { [s.optionSelect]: isSelected }, { [s.optionFocused]: isFocused })}
    >
      <span>{data.label}</span>
    </div>
  ) : null;

const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  className,
  inputTheme,
  error,
  instanceId,
  ...props
}) => (
  <div className={cx(s.container, { [s.errorLabel]: error }, className)}>
    {label && (
      <div className={cx(s.label, { [s.labelPadding]: inputTheme?.includes('labelPadding') })}>
        {label}
      </div>
    )}
    <div
      className={cx(s.select, {
        [s.h46]: inputTheme?.includes('h46'),
      })}
    >
      <Select
        components={{
          SingleValue: customSingleValue,
          Option: CustomOption,
        }}
        classNamePrefix="customSelect"
        {...props}
        options={props.options as any}
        value={props.value as any}
        className={cx({
          [s.filterSelectError]: error,
          [s.filterSelectSuccess]: props.success,
          [s.filterSelectSelected]:
            Boolean(props.value) || error || props.success || inputTheme?.includes('border'),
          [s.filterSelect]: !(
            Boolean(props.value) ||
            error ||
            props.success ||
            inputTheme?.includes('border')
          ),
        })}
        instanceId={instanceId || 'filterSelect'}
      />
    </div>
    {!inputTheme?.includes('noError') && <span className={s.error}>{error}</span>}
  </div>
);

export default CustomSelect;
