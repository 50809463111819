//@ts-ignore Ignore missing type definition for @cloudinary/react library
import { AdvancedImage, responsive } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

// import { IMAGES_URL } from 'src/utils/constants';

import PlaceholderAsic from '@icons/placeholders/placeholder-asic.svg';
import { Brand, Department, Offer } from '@src/api/models/interfaces';
import { supportsWebp } from '@src/utils/constants';
import Markdown from '@ui/Markdown';
import StyledCard from '@ui/StyledCard';

import styles from './market-item.module.sass';

type MarketItemProps = {
  id: string;
  shortcut: string;
  model: string;
  image: string | undefined;
  description: string;
  brand: Brand;
  offers: Offer[];
  departments: Department[];
};

const MarketItem = ({
  id,
  model,
  description,
  image,
  brand,
  offers,
  shortcut,
  departments,
}: MarketItemProps) => {
  const { t } = useTranslation('common');

  const amount = offers.reduce((acc, { quantity }) => acc + quantity, 0);

  const newOffers = offers.filter(({ quality }) => quality === 100);
  const usedOffers = offers.filter(({ quality }) => quality < 100);

  const newPrices = newOffers.map(({ price }) => price);
  const usedPrices = usedOffers.map(({ price }) => price);

  const newMinPrice = Math.min(...newPrices);
  const usedMinPrice = Math.min(...usedPrices);

  const newMaxPrice = Math.max(...newPrices);
  const usedMaxPrice = Math.max(...usedPrices);

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'zionodes',
    },
  });

  const publicId = image?.split('/')[image.split('/').length - 1].split('.jpg')[0];
  const cldImg = cld.image(process.env.APP_ENV === 'production' ? publicId : `tests/${publicId}`);
  cldImg.format(supportsWebp() ? 'webp' : 'auto');

  return (
    <StyledCard
      link="/marketplace/[shortcut]/[itemShortcut]"
      as={`/marketplace/${departments[0].shortcut}/${shortcut}`}
    >
      <article className={styles.cardContent} key={id} role="button">
        <div className={styles.content}>
          <div className={styles.img}>
            {image ? (
              <AdvancedImage cldImg={cldImg} alt={model} plugins={[responsive()]} />
            ) : (
              <div className={styles.userImgPlaceholder}>
                <PlaceholderAsic />
              </div>
            )}
          </div>
          <div className={styles.descriptionWrapper}>
            <div className={styles.titleBlock}>
              <h3 className={styles.itemName}>{model}</h3>

              <div className={styles.itemBrand}>
                <div className={styles.itemHashBlock}>
                  <span className={styles.itemHash}>{brand.title}</span>
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.details}>
                <div>
                  <div className={styles.detailsBlock}>
                    <div className={cx(styles.detailsItem)}>
                      <Markdown>{description}</Markdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.statisticBlock}>
                <div className={styles.statistic}>
                  <div className={styles.statisticValue}>
                    {amount > 0
                      ? `${amount} ${amount !== 1 ? t('Notation.items') : t('Notation.item')}`
                      : t('Notation.noOffers')}
                  </div>

                  {offers.length > 0 && (
                    <div className={styles.procesWrapper}>
                      {amount === 0 && (
                        <>
                          <span className={styles.soldBefore}>{t('Notation.soldBefore')}</span>
                        </>
                      )}
                      <div className={styles.prices}>
                        <div className={styles.pricesTitles}>
                          {newOffers.length > 0 && (
                            <span className={styles.statisticDescription}>
                              {newMinPrice && t('Notation.new')}
                            </span>
                          )}
                          {usedOffers.length > 0 && (
                            <span className={styles.statisticDescription}>
                              {usedMinPrice && t('Notation.used')}
                            </span>
                          )}
                        </div>
                        <div className={styles.pricesValues}>
                          {newOffers.length > 0 && (
                            <span className={styles.statisticDescription}>
                              {newMinPrice
                                ? `${
                                    newMinPrice === newMaxPrice
                                      ? newMinPrice
                                      : `${newMinPrice} - ${newMaxPrice}`
                                  } ${t('Notation.USDT')}`
                                : ''}
                            </span>
                          )}
                          {usedOffers.length > 0 && (
                            <span className={styles.statisticDescription}>
                              {usedMinPrice
                                ? `${
                                    usedMinPrice === usedMaxPrice
                                      ? usedMinPrice
                                      : `${usedMinPrice} - ${usedMaxPrice}`
                                  } ${t('Notation.USDT')}`
                                : ''}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </StyledCard>
  );
};

export default MarketItem;
