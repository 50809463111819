import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

import MarketItem from '@components/marketplace/MarketItem';
import { useMarketplace } from '@src/api/Api';
import Pagination from '@ui/Pagination';
import ResultsInfo from '@ui/ResultsInfo';
import { Loader } from '@ui/loader/Loader';
import { FiltersQueryParams } from 'src/components/marketplace/Filters/Filters';
import EmptyPage from 'src/ui-kit/emptyPage/emptyPage';

import styles from './MarketplaceItems.module.sass';

type QueryParams = FiltersQueryParams & {
  page?: string;
};

type MarketplaceItemsProps = {
  searchBrand?: string;
};

const MarketplaceItems: React.FC<MarketplaceItemsProps> = ({ searchBrand }) => {
  const { t } = useTranslation('marketplace');
  const router = useRouter();
  const { query } = router as { query: QueryParams };
  const { data, initialLoad } = useMarketplace({
    field: 'title',
    ...(query.shortcut ? { department: query.shortcut } : {}),
    brand: searchBrand,
  });

  if (initialLoad) {
    return <Loader />;
  }

  if (!data?.docs || data.docs.length === 0) {
    return (
      <EmptyPage>
        <p>{t('Marketplace.MarketplaceItems.empty')}</p>
      </EmptyPage>
    );
  }

  return (
    <>
      <div className={styles.resultsInfoWrapper}>
        <ResultsInfo amount={data?.count || 0} />
      </div>
      {data?.docs.map(
        ({ id, title, description, brand, offers, images, shortcut, departments }) => (
          <div className={styles.marketWrapper} key={id}>
            <MarketItem
              model={title}
              shortcut={shortcut}
              id={id}
              image={images[0]}
              brand={brand}
              description={description}
              offers={offers}
              departments={departments}
            />
          </div>
        ),
      )}
      <Pagination maxPage={data?.totalPages || 1} />
    </>
  );
};

export default MarketplaceItems;
