import { useMediaQuery, MediaQueryAllQueryable, MediaQueryMatchers } from 'react-responsive';

export type PartialUseMediaQuery = (
  settings?: Partial<MediaQueryAllQueryable & { query?: string }>,
  device?: MediaQueryMatchers,
  callback?: (matches: boolean) => void,
) => boolean;

export const usePhoneOrWider: PartialUseMediaQuery = (settings = {}, device, callback) =>
  useMediaQuery(
    {
      ...settings,
      minWidth: 426,
    },
    device,
    callback,
  );

export const useLphoneOrWider: PartialUseMediaQuery = (settings = {}, device, callback) =>
  useMediaQuery(
    {
      ...settings,
      minWidth: 621,
    },
    device,
    callback,
  );

export const useTabletOrWider: PartialUseMediaQuery = (settings = {}, device, callback) =>
  useMediaQuery(
    {
      ...settings,
      minWidth: 769,
    },
    device,
    callback,
  );

export const useLtabletOrWider: PartialUseMediaQuery = (settings = {}, device, callback) =>
  useMediaQuery(
    {
      ...settings,
      minWidth: 1025,
    },
    device,
    callback,
  );

export const useDesktopOrWider: PartialUseMediaQuery = (settings = {}, device, callback) =>
  useMediaQuery(
    {
      ...settings,
      minWidth: 1367,
    },
    device,
    callback,
  );
