import cx from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import SearchIcon from '@icons/ui-kit/Search.svg';
import { useDepartments } from '@src/api/Api';
import { Department } from '@src/api/models/interfaces';
import { getSearchParam } from '@src/utils/searchParams';
import Input from '@ui/Input';
import StyledCard from '@ui/StyledCard';
import Button from 'src/ui-kit/button/Button';
import CustomSelect from 'src/ui-kit/select/Select';

import s from './filters.module.sass';

export type FiltersQueryParams = {
  query?: string;
  shortcut?: string;
};

export const getDepartmentValue = (str: string | undefined, list: Department[]) => {
  const found = list.find((item: Department) => item.shortcut === str);
  return found ? { value: found.shortcut, label: found.title } : { value: '', label: 'All' };
};

const Filters: React.FC = () => {
  const router = useRouter();

  const query = getSearchParam('query') || '';

  const [search, setSearch] = useState(query);
  const [currentDepartment, setDepartment] = useState(router.query.shortcut as string);

  const departmentsData = useDepartments();
  const departments = (departmentsData && departmentsData.docs) || [];

  const selectableDepartments = departments.map(({ title, shortcut }: Department) => ({
    value: shortcut,
    label: title,
  }));

  const displayDepartments = [{ value: '', label: 'All' }, ...selectableDepartments];

  useEffect(() => {
    setDepartment(router.query.shortcut as string);
  }, [router.query.shortcut]);
  useEffect(() => {
    setSearch(query);
  }, [query]);

  const searchDepartment = () => {
    const isAllDepartment = !currentDepartment || currentDepartment === '';
    const url = isAllDepartment ? '/marketplace' : '/marketplace/[shortcut]';
    const asPath = `/marketplace${!isAllDepartment ? `/${currentDepartment}` : ''}`;

    router.push(
      url,
      {
        pathname: asPath,
        query: {
          query: search || '',
        },
      },
      { shallow: true },
    );
  };

  return (
    <div className={s.wrapper}>
      <StyledCard theme="reverse">
        <div className={s.container}>
          <div className={s.select}>
            <CustomSelect
              value={getDepartmentValue(currentDepartment, departments)}
              inputTheme="noError border"
              options={displayDepartments}
              label=""
              onChange={(selected) => {
                setDepartment((selected as typeof displayDepartments[0]).value || '');
              }}
            />
          </div>
          <div className={cx(s.select, s.search)}>
            <Input
              name="query"
              placeholder="Search..."
              active
              value={search}
              onChange={(newQuery) => {
                setSearch(newQuery.target.value);
              }}
              theme="widthMax h40 noMargin"
            />
          </div>
          <div className={s.button}>
            <Button theme="superSmall ternary" onClick={searchDepartment}>
              <SearchIcon />
            </Button>
          </div>
        </div>
      </StyledCard>
    </div>
  );
};

export default Filters;
