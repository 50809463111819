import * as cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

import PlaceholderAsic from '@icons/placeholders/placeholder-asic.svg';
import { useFullProfile, useTotalBasket } from '@src/api/Api';
import { UserRole } from '@src/api/models/enums';
import { useTabletOrWider, useLtabletOrWider, useDesktopOrWider } from '@utils/mediaQuery';
import Button from 'src/ui-kit/button/Button';

import styles from './cart-card.module.sass';

type CartCardProps = {
  className?: string;
};

let MAX_ITEMS_TO_SHOW = 3;

const CartCard: React.FC<CartCardProps> = ({ className }) => {
  const isTabletOrWider = useTabletOrWider();
  const isLtabletOrWider = useLtabletOrWider();
  const isDesktopOrWider = useDesktopOrWider();
  if (isLtabletOrWider && !isDesktopOrWider) {
    MAX_ITEMS_TO_SHOW = 1;
  }
  if (!isLtabletOrWider) {
    MAX_ITEMS_TO_SHOW = 2;
  }
  if (!isTabletOrWider) {
    MAX_ITEMS_TO_SHOW = 0;
  }
  const { t } = useTranslation(['marketplace', 'common']);

  const router = useRouter();

  const userInfo = useFullProfile();
  const isSeller = userInfo?.data?.role === UserRole.SELLER;
  const { data, initialLoad } = useTotalBasket({ skip: userInfo.initialLoad || isSeller });

  if ((!isSeller && initialLoad) || !data?.items || data.items.length === 0 || isSeller) {
    return null;
  }

  const itemsCount = data.items.reduce((accum, el) => {
    return accum + el.quantity;
  }, 0);

  return (
    <div className={cx(styles.cart, className)}>
      <div className={styles.leftBlock}>
        <div className={styles.textBlock}>
          <div>
            <span className={styles.label}>
              {t('common:Notation.there')}{' '}
              {itemsCount > 1 ? t('common:Notation.are') : t('common:Notation.is')}{' '}
              <span className={styles.labelText}>
                {itemsCount}{' '}
                {itemsCount > 1 ? t('common:Notation.items') : t('common:Notation.item')}
              </span>{' '}
              {t('Marketplace.cartCard.inCart')}
            </span>
          </div>
          <div>
            <span className={styles.subLabel}>
              {t('Marketplace.cartCard.worth')} {data.total.toFixed(2)} {t('common:Notation.USDT')}
            </span>
          </div>
        </div>
        {data.items.slice(0, MAX_ITEMS_TO_SHOW).map((item) => (
          <div key={item.offer?.id} className={styles.imgBlock}>
            {item.offer?.item.images[0] ? (
              <div className={styles.imgInner}>
                <img src={item.offer.item.images[0]} alt={item.offer.item.title} />
              </div>
            ) : (
              <div className={styles.imgPlaceholder}>
                <PlaceholderAsic />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={styles.rightBlock}>
        <div>
          <Button onClick={() => router.push('/basket')}>
            <span className={styles.buttonText}>{t('common:Buttons.toOrder.default')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartCard;
