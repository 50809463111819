import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Cross from '@icons/ui-kit/Cross.svg';
import Filter from '@icons/ui-kit/mobileFilter.svg';
import FilterDarkTheme from '@icons/ui-kit/mobileFilterDarkTheme.svg';
import { useDepartments } from '@src/api/Api';
import { useCurrentTheme, THEME } from '@src/api/local/theme';
import { Department } from '@src/api/models/interfaces';
import { getSearchParam } from '@src/utils/searchParams';
import Input from '@ui/Input';
import Button from 'src/ui-kit/button/Button';
import CustomSelect from 'src/ui-kit/select/Select';

import { getDepartmentValue } from '../Filters/Filters';

import s from './filtersMobile.module.sass';

const FiltersMobile = () => {
  const { t } = useTranslation('common');

  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  const router = useRouter();

  const query = getSearchParam('query') || '';

  const [shownFilters, setShownFilters] = useState(false);
  const [search, setSearch] = useState(query);
  const [currentDepartment, setDepartment] = useState(router.query.shortcut as string);

  const departmentsData = useDepartments();
  const departments = (departmentsData && departmentsData.docs) || [];

  const selectableDepartments = departments.map(({ title, shortcut }: Department) => ({
    value: shortcut,
    label: title,
  }));

  const displayDepartments = [{ value: '', label: 'All' }, ...selectableDepartments];

  useEffect(() => {
    setDepartment(router.query.shortcut as string);
  }, [router.query.shortcut]);
  useEffect(() => {
    setSearch(query);
  }, [query]);

  const searchDepartment = () => {
    const isAllDepartment = !currentDepartment || currentDepartment === '';
    const url = isAllDepartment ? '/marketplace' : '/marketplace/[shortcut]';
    const asPath = `/marketplace${!isAllDepartment ? `/${currentDepartment}` : ''}`;

    router.push(
      url,
      {
        pathname: asPath,
        query: {
          query: search || '',
        },
      },
      { shallow: true },
    );
  };

  return (
    <>
      <button className={s.placeholder} type="button" onClick={() => setShownFilters(true)}>
        {t('Notation.filter')}
        {isLightTheme ? <Filter /> : <FilterDarkTheme />}
      </button>
      <div className={cx(s.wrapper, { [s.active]: shownFilters })}>
        <div className={s.wrapperInner}>
          <div className={s.heading}>
            <div>
              <div className={s.headingButton}>
                <Button theme="flexible ternary superSmall" onClick={() => setShownFilters(false)}>
                  <Cross />
                </Button>
              </div>
            </div>
            <div className={s.headingMiddle}>
              {t('Notation.filter')}
              {isLightTheme ? <Filter /> : <FilterDarkTheme />}
            </div>
            <button
              onClick={() => {
                setDepartment('');
                setSearch('');
                setShownFilters(false);
                searchDepartment();
              }}
              type="button"
              className={s.headingReset}
            >
              {t('Notation.reset')}
            </button>
          </div>
          <div className={s.container}>
            <div className={s.select}>
              <CustomSelect
                value={getDepartmentValue(currentDepartment, departments)}
                inputTheme="noError"
                options={displayDepartments}
                label=""
                onChange={(selected) => {
                  setDepartment((selected as typeof displayDepartments[0]).value || '');
                }}
              />
            </div>

            <div className={cx(s.select, s.search)}>
              <Input
                name="query"
                placeholder="Search..."
                active
                value={search}
                onChange={(newQuery) => {
                  setSearch(newQuery.target.value);
                }}
                theme="widthMax centeredContent h40 noBorder noMargin"
              />
            </div>
            <div className={s.button}>
              <Button
                theme="flexible"
                onClick={() => {
                  searchDepartment();
                  setShownFilters(false);
                }}
              >
                <span>{t('Buttons.find.default')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltersMobile;
