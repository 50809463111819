import { useTranslation } from 'next-i18next';
import React from 'react';

import s from './results-info.module.sass';

type ResultsInfoProps = {
  amount: number | null;
};

const ResultsInfo: React.FC<ResultsInfoProps> = ({ amount }) => {
  const { t } = useTranslation('common');
  let text;

  if (amount && amount > 0) {
    text = `${amount} ${amount !== 1 ? t('Notation.resultsFound') : t('Notation.resultFound')}`;
  } else if (amount === null) {
    text = 'Oooops... No results found. Please, try again later!';
  } else {
    text = 'Oooops... No results found. Please, change filter properties!';
  }

  return (
    <div className={s.resultsInfo}>
      <span>{text}</span>
    </div>
  );
};

export default ResultsInfo;
