import markdownPro from 'markdown-pro';
import React from 'react';

export type MarkdownProps = {
  children: string;
  useLineBreak?: boolean;
  className?: string;
  parseLink?: boolean;
};

const Markdown = ({
  children,
  useLineBreak = true,
  className,
  parseLink = true,
}: MarkdownProps) => {
  const config = {
    useLineBreak,
    wrapperClassName: className,
    parseLink,
    useWrapper: false,
  };
  const formattedChildren = markdownPro(children, config);
  return <div dangerouslySetInnerHTML={{ __html: formattedChildren }} />;
};

export default Markdown;
